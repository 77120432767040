import { graphql } from "gatsby";
import { PDFViewer } from 'components/PDFViewer';
import * as styles from './Preview.module.css'
import { DatoDocumentType } from "@src/components/Document/PageTemplates/Category/Three/query";
import React from "react";
import { MathJaxContext } from "better-react-mathjax";
import { Layout, SEO } from "@src/components";
import { isFallbackUrl } from "@src/utils/documents/url";

interface DocumentPreviewProps {
    data: {
        datoCmsDocument: DatoDocumentType;
    }
}

const removeTrailingS = (s: string | undefined): string | undefined => {
  if (s && s.charAt(s.length - 1) === 's') {
    return s.slice(0, -1);
  }
  return s;
}

function toTitleCase(str: string) {
  const capitalised = str.replace(
    /\w\S*/g,
    text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
  const acronymsCapitalised = capitalised.replace(/\d\w(\W|$)/g, text => text.charAt(0).toUpperCase() + text.substring(1).toUpperCase());
  return acronymsCapitalised;
}

const courseNameToTitleName = (courseName: string) => {
  let str = courseName.replace(' GCE', '')
  return str;
}

const docNameToTitleName = (docName: string) => {
  let str = docName.toLowerCase();
  str = str.replace(' detailed', '');
  str = toTitleCase(str);
  str = str.replace(' Ms', ' MS');
  return str;
}

export const generateMetaTitle = (doc: DatoDocumentType) => {
  const examBoard = doc.docExamBoard?.[0]?.name || '';
  const course = doc.docExamCourse?.[0]?.name || '';

  const titleParts = [];
  if (examBoard) titleParts.push(examBoard);
  if (course) titleParts.push(courseNameToTitleName(course));
  titleParts.push('Maths');
  titleParts.push(docNameToTitleName(doc.name));

  return titleParts.join(' ');
}

const docNameToDescriptionName = (docName: string) => {
  let str = docName.toLowerCase();
  str = str.replace(' detailed written ms', ' detailed written model solutions');
  str = str.replace(' written ms', ' detailed written model solutions');
  str = str.replace(' ms', ' mark scheme');
  str = str.replace(' mechs ', ' mechanics ');
  str = str.replace(' stats ', ' statistics ');
  return toTitleCase(str);
}

export const generateMetaDescription = (doc: DatoDocumentType) => {
  // const docType = removeTrailingS(doc.docType?.name?.toLowerCase()) || 'document';
  // let docSubType = undefined;
  // switch (doc.docPastPaperType?.name) {
  //   case 'Written Mark Scheme':
  //     docSubType = 'detailed written model solutions'
  //     break;
  //   case 'Mark Scheme':
  //     docSubType = 'mark scheme'
  //     break;
  //   default:
  //     docSubType = undefined;
  // }
  const examBoard = doc.docExamBoard?.[0]?.name || '';
  const course = doc.docExamCourse?.[0]?.name || '';
  const code = doc.docExamCourseCode?.[0]?.name || '';
  // const modules = doc.docExamModules?.map((m) => m.name).join(', ') || '';
  const tier = doc.docExamTier?.[0]?.name || '';
  const examYear = doc.docExamYear;

  const parts = [];
  parts.push('Here you will find the')
  if (examYear) parts.push(examYear);
  if (examBoard) parts.push(examBoard);
  if (course) parts.push(course);
  parts.push(docNameToDescriptionName(doc.name));

  // parts.push(docType);
  // if (docSubType) parts.push(docSubType);
  parts.push('for the')
  if (examBoard) parts.push(examBoard);
  if (course) parts.push(course);
  if (code) parts.push(code);
  // if (modules) parts.push(modules);
  if (tier) parts.push(tier);
  parts.push('Maths course at MyMathsCloud.')
  return parts.join(' ');
}

const DocumentPreview = ({data}: DocumentPreviewProps) => {
    const { datoCmsDocument: document } = data;

    const fileUrl = document.fileUrl.includes('https://mymathscloud.ams3.digitaloceanspaces.com/')
        ? unescape(document.fileUrl.replace('https://mymathscloud.ams3.digitaloceanspaces.com/', ''))
        : document.fileUrl;

    const downloadLink = `${process.env.GATSBY_BACKEND_URL}/api/download/modules/${fileUrl}?id=${document.originalId}`;

    const examBoard = document.docExamBoard?.length ? document.docExamBoard?.map((m) => m.name).join(', ') : undefined;
    const course = document.docExamCourse?.length ? document.docExamCourse?.map((m) => m.name).join(', ') : undefined;
    const modules = document.docExamModules?.length ? document.docExamModules?.map((m) => m.name).join(', ') : undefined;
    const examYear = document.docExamYear;

    const SEOTitle = generateMetaTitle(document);
    const SEODesctiption = generateMetaDescription(document);
    const SEOShouldNoIndex = isFallbackUrl(document);
    
    return (
        <MathJaxContext>
            <Layout>
              <SEO
                  description={SEODesctiption}
                  title={SEOTitle}
                  schema={undefined} 
                  noIndex={SEOShouldNoIndex}
                  seoKeywords={undefined}
              />
                <article className="content-section container">
                    <div className={styles.metaContent}>
                        <h2>{document.name}</h2>
                        {examBoard ? <span><b>Exam Board:</b> {examBoard}</span> : null}
                        {course ? <span><b>Course:</b> {course}</span> : null}
                        {modules ? <span><b>Modules:</b> {modules}</span> : null}
                        {examYear ? <span><b>Year:</b> {examYear}</span> : null}
                        <a href={downloadLink} target="_blank" rel="noreferrer">Download Link</a>
                    </div>

                    {
                      document.description2 ? (
                        <div className={styles.description} dangerouslySetInnerHTML={{ __html: document.description2 }} />
                      ) : null
                    }

                    <div className="pb-0.5 flex-1">
                        <PDFViewer url={downloadLink} hideWaterMark print />
                    </div>
                </article>
            </Layout>
        </MathJaxContext>
    )
}

export default DocumentPreview;

const query = graphql`
  query DocumentPreviewQuery($id: String!) {
    datoCmsDocument(id: { eq: $id }) {
      id
      originalId
      hasPassword
      freeSample
      freeDocument
      fileUrl
      externalUrl
      hidePreview
      hideOpenInNewWindow
      description
      name
      displayName
      price
      title
      keywords
      isFeatured
      previewUrl
      fileDescription
      description2
      videoGuide {
        url
        thumbnailUrl
      }
      priceCode
      seo {
        title
        description
        twitterCard
      }
      docType {
        id
        name
      }
      docPastPaperType {
        id
        name
      }
      docExamCourse {
        id
        name
      }
      docExamBoard {
        id
        name
      }
      docExamModules {
        id
        name
      }
      docRelatedTopics {
        id
        name
      }
      docExamCourseCode {
        id
        name
      }
      docExamTier {
        id
        name
      }
      docExamPracticeType {
        id
        name
      }
      docExamTextbookType {
        id
        name
      }
    }
    datoCmsSubscriptionsModal {
      delayForFreePasswordDocuments
    }
  }
`;

export { query };
