export const filterSection = (section, sectionName, data) =>
  data[section].filter((section) => section.name === sectionName)[0];

export const download_file = (url, name) =>
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${name}.pdf`;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
    });

export const removeHyphens = (text) => {
  return text?.replace(/-/g, ' ');
};

export const formatQualification = (qualification) => {
  if (!qualification) {
    return null;
  }

  // Regular expression to match 'AS-Level' or 'A-Level' at the start
  const match = qualification.match(/^(AS-Level|A-Level)(-)?(.*)$/);

  if (match) {
    const prefix = match[1]; // 'AS-Level' or 'A-Level'
    let rest = match[3]; // The rest of the string after the prefix

    // Remove leading hyphen if present
    if (rest.startsWith('-')) {
      rest = rest.substring(1);
    }

    // Replace hyphens with spaces in the remaining part
    rest = rest.replace(/-/g, ' ');

    // Combine the prefix and the processed remaining part
    return rest ? `${prefix} ${rest}` : prefix;
  } else {
    return qualification.replace(/-/g, ' ');
  }
};

export const extractDatoCmsId = (input) => {
  const match = input?.match(/^DatoCmsDocument-(.*?)-/);
  return match ? match[1] : null;
};
